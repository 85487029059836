<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <img src="../../assets/image/news/newsBg.png" alt="">
    </div>
    <div class="qyhxys yj-news">
      <div v-if="newsList.length>0">
        <div class="newsCon" v-for="(item,index) in newsList" :key="index" @click="toDetail(item.id)">
          <div class="left news-pic">
            <img :src="item.pic" alt=""/>
          </div>
          <div class="right">
            <h3>{{item.title}}</h3>
            <p class="news-desc">{{item.illustrate}}</p>
            <div class="newsDate">发布日期：{{ $moment(item.createTime).format('YYYY-MM-DD') }}</div>
            <div class="moreA" @click="toDetail(item.id)">了解详情</div>
          </div>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
      <div class="pagination">
        <page-bean
          @clickPage="clickPage"
          :total="totlePage"
        ></page-bean>
      </div>
    </div>
  </div>
</template>
<script>
import pageBean from "@/components/pageBean.vue";
import { owJournalism } from "@/api/api";
export default {
  name: "industryNews",
  components: {
    pageBean,
  },
  data(){
    return {
      totlePage: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
      newsList:[]
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      let params = this.queryParams
      owJournalism(params).then((res) => {
        if (res.success == true) {
          this.newsList = res.data.records;
          this.totlePage = res.data.total;
        }
      });
    },
    clickPage(obj) {
      this.queryParams.pageSize = obj.pageSize;
      this.queryParams.currentPage = obj.currentPage;
      this.getList();
    },
    toDetail(id){
      this.$router.push({
        path:"/detail",
        query: { id: id, activeIndex:"2"}
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.qyhxys{
  height: auto;
}
 .yj-news {
   height: auto;
   .newsCon {
    padding: 40px;
    background: #FFFFFF;
    margin-bottom: 40px;
   .left img{
    width: 400px;
    height: 260px;
    transition: 0.5s;
   }
   &:hover{
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    img{
      transform: scale(1.02)
    }
   }
   .right{
    padding: 0 0 0 35px;
    box-shadow: none;
    height: auto;
    margin-left: 15px;
    .news-desc{
      margin-top: 30px;
    }
    .newsDate{
      position: absolute;
      left: 3.125rem;
      bottom: 0.5rem;
    }
    .moreA{
      position: absolute;
      right: 0;
      left: auto;
      bottom: 0.5rem;
    }
   }
  }
}
</style>
